module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicons/favicon-32x32.png","icons":[{"src":"src/images/favicons/favicon.ico","sizes":"48x48","type":"image/x-icon"},{"src":"src/images/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/images/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"08bc6032047660fdf03476c55ae04044"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JZ2NX3CPF2"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
